<template>
  <v-dialog v-model="open" max-width="400">
    <v-card>
      <v-card-title>{{ $t('deleteConfirmationMessage') }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="open = false">{{
          $t('no')
        }}</v-btn>
        <v-btn color="primary" text @click="deleteExpense">{{
          $t('yes')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    expenseToDelete: Object,
    showDelete: Boolean,
  },

  computed: {
    open: {
      get() {
        return this.showDelete;
      },
      set(value) {
        this.$emit('update:showDelete', value);
      },
    },
  },

  methods: {
    deleteExpense() {
      this.$store.dispatch('deleteExpense', {
        expense: this.expenseToDelete,
      });
      this.open = false;
    },
  },
};
</script>

